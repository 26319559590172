import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import PagePrismic from '@/prismic/types/Page.prismic'
import HomePrismic from '@/prismic/types/Home.prismic'
import BlogPostPrismic from '@/prismic/types/BlogPost.prismic'
import ErrorTemplate from '@/templates/Error'

const NotFoundPage = ({ path }) => (
  <ErrorTemplate title="Page Not found" path={path}>
    <p>
      If you entered a web address please check it was correct, <br />
      or <Link to="/">click here</Link> to go to the homepage.
    </p>
  </ErrorTemplate>
)

NotFoundPage.defaultProps = {
  path: undefined,
}

NotFoundPage.propTypes = {
  path: PropTypes.string,
}

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    flexible: PagePrismic,
    home: HomePrismic,
    blog_post: BlogPostPrismic,
  },
})
